:root {
    --primary: rgb(31, 251, 166);
    --secondary: #292929;
    --text-color: white;
    --background: black;
    --regular-font-size: 18px;
    --title-font-size: 30px;
    --heading-font-size: 22px;
    --small-font-size: 12px;
    --field-border-gradient: linear-gradient(145deg, rgba(41, 41, 41, 1) 60%, rgba(255, 255, 255, 1) 100%);
}

body {
    margin: 0;
    color: var(--text-color);
    font-family: 'Anton', sans-serif;
    background: var(--background);
    /* font-family: 'Poppins', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Helper CSS Classes */

.help {
    border: solid red 2px;
}

.flex1 {
    flex: 1
}

.flex2 {
    flex: 2
}

.flex3 {
    flex: 3
}

.flex4 {
    flex: 4
}

.hoverable {
    &:hover {
        opacity: 0.9;
    }
}

.scaleUp {
    &:hover {
        transition: 0.2s;
        transform: scale(1.05);
    }
    &:active {
        transition: 0.1s;
        transform: scale(1);
    }
}

.clickable {
    &:hover {
        cursor: pointer;
    }
    &:active {
        transition: 0.2s;
        transform: scale(0.95);
    }
}

button {
    padding: 10px;
    padding-inline: 50px;
    border: none;
    border-radius: 30px;
    background-color: var(--primary);
    font-weight: 600;
    font-family: 'Poppins';
    font-weight: 700;
    margin: 5px;
}

.field {
    border: solid 2px;
    border-color: var(--field-border-gradient);
    border-radius: 30px;
    background-color: transparent;
    padding: 10px;
    padding-left: 15px;
    color: var(--text-color);
    font-size: var(--regular-font-size);
}