.navbar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100px;
    background: var(--background);
    .logo {
        color: var(--primary);
        font-size: var(--title-font-size);
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .navigation-tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        .tab {
            color: var(--text-color);
            text-decoration: none;
            font-size: var(--regular-font-size);
        }
    }
}

.beta {
    color: violet;
    font-style: italic;
    font-size: 20px;
    animation: spin 3s infinite;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
        box-shadow: var(--primary) 0px 7px 29px 0px;
        padding: 7px;
        border-radius: 30px;
        text-shadow: .1em .1em 0 var(--primary);
        /* Fully visible */
    }
    50% {
        opacity: 0;
        /* Invisible */
    }
    100% {
        opacity: 1;
        /* Fully visible */
    }
}

@keyframes spin {
    from {
        transform: rotateY(0deg);
        /* Starting position, no rotation */
    }
    to {
        transform: rotateY(360deg);
        box-shadow: var(--primary) 0px 7px 29px 0px;
        padding: 15px;
        border-radius: 30px;
        text-shadow: .1em .1em 0 var(--primary);
        /* Full rotation, change to the desired angle */
    }
}