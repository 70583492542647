.home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    .todo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 30px;
        width: 80%;
        border: solid white 3px;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        border-radius: 50px;
        padding-bottom: 70px;
    }
}

.search {
    width: 100%;
    .search-bar {
        background-color: var(--secondary);
        border-radius: 30px;
        border: none;
        padding: 15px;
        color: var(--text-color);
        font-size: var(--regular-font-size);
        padding-left: 25px;
        margin-bottom: 20px;
        width: 70%;
    }
}