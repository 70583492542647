.todo {
    background-color: var(--secondary);
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    .task {
        text-align: start;
        padding-left: 30px;
        font-size: var(--regular-font-size);
        p {
            display: flex;
            word-wrap: break-word;
            overflow: auto;
            text-align: start;
        }
    }
    .options {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}

.complete {
    text-decoration: line-through;
    text-decoration-line: line-through;
    text-decoration-color: red;
    text-decoration-thickness: 5px;
    opacity: 0.5;
}

.cust {
    width: 80%;
}