.todoform {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: center;
    padding-block: 50px;
    .cust {
        width: 60%;
        border: none;
    }
}